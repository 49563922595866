<template>
  <div id="app" class="bg-white">
    <div class="relative bg-white">
      <div
        class="
          flex
          justify-between
          items-center
          max-w-7xl
          mx-auto
          px-4
          py-6
          sm:px-6
          md:justify-start md:space-x-10
          lg:px-8
        "
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <a href="/">
            <span class="logo_text text-gray-600 text-5xl">L</span>
          </a>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button
            type="button"
            class="
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500 hover:bg-gray-100
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-indigo-500
            "
            aria-expanded="false"
            @click="updateShowMenu"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav class="hidden md:flex space-x-10">
          <a
            href="/blog"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Blog
          </a>
          <a
            href="/about"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            About
          </a>
          <a
            href="/contact"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Contact
          </a>
          <a
            href="/store"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Store
          </a>
        </nav>
        <div
          class="hidden md:flex items-center justify-end md:flex-1 lg:w-0"
        ></div>
      </div>

      <!--
        Mobile menu, show/hide based on mobile menu state.

        Entering: "duration-200 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      -->
      <div
        class="
          absolute
          z-30
          top-0
          inset-x-0
          p-2
          transition
          transform
          origin-top-right
          md:hidden
        "
        v-show="showMenu"
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <a href="/">
                  <span class="logo_text text-gray-600 text-5xl">L</span>
                </a>
              </div>
              <div class="-mr-2">
                <button
                  type="button"
                  class="
                    bg-white
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    text-gray-400
                    hover:text-gray-500 hover:bg-gray-100
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-indigo-500
                  "
                  @click="updateShowMenu"
                >
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: outline/x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6"></div>
          </div>
          <div class="py-6 px-5">
            <div class="grid grid-cols-2 gap-4">
              <a
                href="/blog"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Blog
              </a>
              <a
                href="/about"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                About
              </a>
              <a
                href="/contact"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Contact
              </a>
              <a
                href="/store"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Store
              </a>
            </div>
            <div class="mt-6"></div>
          </div>
        </div>
      </div>
    </div>
    <main>
      <router-view />
    </main>
    <footer class="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <!-- <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Solutions
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Marketing
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Analytics
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Commerce
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Insights
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Support
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Pricing
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Documentation
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Guides
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    API Status
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Company
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    About
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Blog
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Jobs
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Press
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Partners
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Claim
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Privacy
                  </a>
                </li>

                <li>
                  <a href="#" class="text-base text-gray-500 hover:text-gray-900">
                    Terms
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-12 xl:mt-0">
          <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
            Subscribe to our newsletter
          </h3>
          <p class="mt-4 text-base text-gray-500">
            The latest news, articles, and resources, sent to your inbox weekly.
          </p>
          <form class="mt-4 sm:flex sm:max-w-md">
            <label for="email-address" class="sr-only">Email address</label>
            <input type="email" name="email-address" id="email-address" autocomplete="email" required class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400" placeholder="Enter your email">
            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button type="submit" class="w-full flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div> -->
        <div
          class="
            mt-12
            border-t border-gray-200
            pt-8
            md:flex md:items-center md:justify-between
            lg:mt-16
          "
        >
          <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2023 Lora Vyver. All rights reserved.
          </p>
          <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            Made with love and admiration by <a target="_blank" href="https://speroleague.com" class="text-gray-600">Spero League</a>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    updateShowMenu() {
      return (this.showMenu = !this.showMenu);
    },
  },
};
</script>

<style>
.logo_text {
  font-family: "Sacramento", cursive;
}

.hope_text {
  font-family: "Caveat Brush", cursive;
}
</style>
