<template>
    <div>
        <div class="container mx-auto my-8">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="
            relative
            bg-gray-50
            pt-16
            pb-20
            px-4
            sm:px-6
            lg:pt-24 lg:pb-28 lg:px-8
          ">
                <div class="absolute inset-0">
                    <div class="bg-white h-1/3 sm:h-2/3"></div>
                </div>
                <div class="relative max-w-7xl mx-auto">
                    <div class="text-center">
                        <h2 class="
                  text-3xl
                  tracking-tight
                  font-extrabold
                  text-gray-900
                  sm:text-4xl
                ">
                            Welcome
                        </h2>
                    </div>
                    <div class="
                mt-12
                max-w-lg
                mx-auto
                grid
                gap-5
                lg:grid-cols-3 lg:max-w-none
              ">
                        <a class="flex flex-col rounded-lg shadow-lg overflow-hidden cursor-pointer" v-for="item in items"
                            :key="item.id"
                            :href="`mailto:lora@loravyver.com?subject=Purchase ${item.name}`"
                            >
                            <div class="flex-shrink-0">
                                <img v-if="item.picture" class="h-48 w-full object-cover" :src="item.picture.url"
                                    alt="" />
                            </div>
                            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                                <div class="flex-1">
                                    <div class="block mt-2">
                                        <p class="text-xl font-semibold text-gray-900">
                                            {{ item.name }}
                                        </p>
                                        <p class="mt-3 text-base font-medium text-xl text-gray-500">
                                           <b>${{ item.cost }}</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { gql } from "graphql-request";

export default {
    data() {
        return {
            items: [],
        };
    },
    async mounted() {
        const data = await this.$graphcms.request(
            gql`
          {
            stores {
              name
              cost
              picture {
                url
              }
            }
          }
        `

        );

        this.items = data.stores;
    },
};
</script>