<template>
  <div>
    <div class="container mx-auto my-8">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div
        class="
          relative
          bg-gray-50
          pt-16
          pb-20
          px-4
          sm:px-6
          lg:pt-24 lg:pb-28 lg:px-8
        "
      >
        <div class="absolute inset-0">
          <div class="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <h2
              class="
                text-3xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-4xl
              "
            >
              Welcome
            </h2>
          </div>
          <div
            class="
              mt-12
              max-w-lg
              mx-auto
              grid
              gap-5
              lg:grid-cols-3 lg:max-w-none
            "
          >
            <a
              class="flex flex-col rounded-lg shadow-lg overflow-hidden"
              v-for="post in posts"
              :key="post.id"
              :href="`/post/${post.slug}`"
            >
              <div class="flex-shrink-0">
                <img
                  v-if="post.coverImage"
                  class="h-48 w-full object-cover"
                  :src="post.coverImage.url"
                  alt=""
                />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <div class="flex flex-row">
                    <!-- <p
                        class="text-sm font-medium text-indigo-600 mr-2"
                        v-for="tag in post.tags"
                        :key="`${post.title}-${tag}`"
                      >
                        <a :href="`/post/${post.slug}`" class="hover:underline">
                          {{ tag }}
                        </a>
                      </p> -->
                  </div>
                  <a :href="`/post/${post.slug}`" class="block mt-2">
                    <p class="text-xl font-semibold text-gray-900">
                      {{ post.title }}
                    </p>
                    <p class="mt-3 text-base text-gray-500">
                      {{ post.excerpt }}
                    </p>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gql } from "graphql-request";

export default {
  data() {
    return {
      posts: [],
    };
  },
  async mounted() {
    const data = await this.$graphcms.request(
      gql`
        {
          posts {
            title
            slug
            date
            excerpt
            coverImage {
              url
            }
            content {
              text
            }
            tags
            author {
              name
            }
            seo {
              keywords
            }
          }
        }
      `
    );
    this.posts = data.posts;
  },
};
</script>