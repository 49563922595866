import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { GraphQLClient } from 'graphql-request'

Vue.config.productionTip = false

const graphcmsClient = new GraphQLClient(
  'https://api-us-east-1.graphcms.com/v2/ckwseubip48ri01xmax4bhddh/master'
)

Vue.mixin({
  beforeCreate() {
    this.$graphcms = graphcmsClient;
  },
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
